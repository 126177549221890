<template>
    <div>
        <div class="modal-backdrop fade show"></div>
        <div class="modal custom-fields show" id="new-user-modal" style="display: block">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <form action="">
                    <div class="modal-content" v-on-click-away="closeModal">
                        <div class="modal-header">
                            <h4 class="modal-title text-uppercase">{{ $t("invite new candidate") }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="closeModal()">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p v-if="job" class="mb-2">{{ $t('You are inviting candidates to job') }} <span class="font-weight-bold">{{ job.title }}</span></p>
                            <div>
                                <label>{{ $t("Email") }}*</label>
                                <div class="input-group mb-3">
                                    <input type="email" class="form-control" v-bind:class="{ 'has-error': errors.email.length }" name="email" v-model.trim="form.email" placeholder="user@easy.jobs">
                                    <button class="button info-button text-capitalize" type="button" @click="addCandidateToJob">{{ $t('invite') }}</button>
                                </div>
                                <div class="error-message mb-3" v-text="errors.email"></div>
                            </div>
                            <div class="content-area__body" style="padding: 0; margin-top: 10px;">
                                <div class="data-table user-table" v-if="!isEmpty">
                                    <div class="table-wrap">
                                        <div class="table table-modal">
                                            <div class="table__row table__head">
                                                <div class="table-cell">{{ $t(`Name`) }}</div>
                                                <div class="table-cell">{{ $t(`Email`) }}</div>
                                                <div class="table-cell" style="width: 110px"><span class="d-flex justify-content-end">{{ $t(`Actions`) }}</span></div>
                                            </div>
                                            <div class="table__row" v-for="invite in invitations">
                                                <div class="table-cell">{{ invite.name }}</div>
                                                <div class="table-cell"><span class="text-break">{{ invite.email }}</span></div>
                                                <div class="table-cell" style="width: 110px">
                                                    <div class="d-flex justify-content-end">
                                                        <a href="javascript:void(0)" class="control-button control-button--warning has_bg_color" @click="reInvite(invite.email)">
                                                            <div class="control-button__icon">
                                                                <i class="eicon e-send"></i>
                                                            </div>
                                                        </a>
                                                        <a href="javascript:void(0)" class="control-button control-button--danger has_bg_color" @click="deleteInvite(invite.email)">
                                                            <div class="control-button__icon">
                                                                <i class="eicon e-delete"></i>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h6 v-else style="color: #ff5f74;" class="mt-2">{{ $t('No invitations found.') }}</h6>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</template>

<script>
import client from "../../../app/api/Client";

export default {
    props: ['jobId', 'job'],
    data() {
        return {
            form: {
                email: '',
            },
            errors: {
                email: '',
            },
            invitations: [],
            isLoading: false
        }
    },
    computed: {
        isEmpty() {
            return this.invitations.length === 0;
        }
    },
    methods: {
        reInvite(email) {
            this.form.email = email;
            this.addCandidateToJob();
        },
        closeModal() {
            this.$emit('closeModal');
        },
        selectAll() {
            this.selected = this.allSelected ? Object.keys(this.permissions) : [];
        },
        async getInvitations(queryData = null) {
            this.invitations = [];
            this.form.email = '';
            try {
                let {data: {data}} = await client().get(`/job/${this.jobId}/invitations`)
                this.invitations = data;
            } catch (error) {
                this.$toast.error(error.response.data.message);
                if (error.response.status === 480 || error.response.status === 490) {
                    await this.$router.push({name: 'dashboard'});
                }
            }
        },
        addCandidateToJob() {
            if (this.isValid()) {
                this.isLoading = true;
                client().post(`job/${this.jobId}/candidate/add`, this.form).then(({data: {data, message}}) => {
                    this.$toast.success(message);
                    this.getInvitations();
                }).catch(({response: {data}}) => {
                    this.$toast.error(data.message);
                }).finally(res => {
                    this.form.email = '';
                });
            }
        },
        deleteInvite(email) {
            this.form.email = email;
            client().post(`job/${this.jobId}/invitation/delete`, this.form).then(({data: {data, message}}) => {
                this.$toast.success(message);
                this.getInvitations();
            });
        },
        isValid() {
            this.errors.email = "";

            if (this.form.email.length < 1) {
                this.errors.email = this.$t("Email field is required");
                return false;
            }

            if (!_helper.validateEmail(this.form.email)) {
                this.errors.email = this.$t("Please type a valid email address");
                return false;
            }
            return true;
        }
    },
    mounted() {
        this.getInvitations();
    }
};
</script>

<style scoped>
.table-modal .table-cell {
    padding: 10px !important;
    background: #f8f8fd !important;
}
</style>
